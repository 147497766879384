import './app.css';
import React from 'react';
import {createRoot} from 'react-dom/client';
import {CommonProvider} from '@common/core/common-provider';
import * as Sentry from '@sentry/react';
import {getBootstrapData} from '@ui/bootstrap-data/bootstrap-data-store';
import {ignoredSentryErrors} from '@common/errors/ignored-sentry-errors';
import {appRouter} from '@app/app-router';
import {FetchCustomPageResponse} from '@common/custom-page/use-custom-page';
import {FtpCredentials} from '@app/dashboard/project';
import {LandingPageContent} from '@app/landing/landing-page-content';
import {BaseBackendBootstrapData} from '@common/core/base-backend-bootstrap-data';
import {BaseBackendSettings} from '@common/core/settings/base-backend-settings';
import {BaseBackendUser} from '@common/auth/base-backend-user';
import {rootEl} from '@ui/root-el';

declare module '@common/http/value-lists' {
  interface FetchValueListsResponse {
    //
  }
}

declare module '@ui/bootstrap-data/bootstrap-data' {
  interface BootstrapData extends BaseBackendBootstrapData {
    loaders?: {
      landingPage?: any;
      customPage?: FetchCustomPageResponse;
    };
  }
}

declare module '@ui/settings/settings' {
  interface Settings extends BaseBackendSettings {
    ai_setup: boolean;
    publish?: {
      default_credentials?: FtpCredentials;
      allow_credential_change?: boolean;
    };
    builder?: {
      template_categories?: string[];
      enable_subdomains?: boolean;
      enable_custom_domains?: boolean;
    };
    homepage?: {
      type?: string;
      value?: string;
      appearance: LandingPageContent;
    };
    ads?: {
      dashboard_top?: string;
      dashboard_bottom?: string;
      disable?: boolean;
    };
  }
}

declare module '@ui/types/user' {
  interface User extends BaseBackendUser {
    //
  }
}

const data = getBootstrapData();
const sentryDsn = data.settings.logging.sentry_public;
if (sentryDsn && import.meta.env.PROD) {
  Sentry.init({
    dsn: sentryDsn,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 0.2,
    ignoreErrors: ignoredSentryErrors,
    release: data.sentry_release,
  });
}

createRoot(rootEl).render(<CommonProvider router={appRouter} />);
