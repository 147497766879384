import {RouteObject} from 'react-router-dom';
import {lazyAdminRoute} from '@common/admin/routes/lazy-admin-route';

export const appAdminRoutes: RouteObject[] = [
  {
    path: 'projects',
    lazy: () => lazyAdminRoute('ProjectsDatatablePage'),
  },
  {
    path: 'templates',
    lazy: () => lazyAdminRoute('TemplatesDatatablePage'),
  },
  {
    path: 'custom-domains',
    lazy: () => lazyAdminRoute('DomainsDatatablePage'),
  },
];
