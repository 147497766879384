import {Outlet, RouteObject} from 'react-router-dom';
import React from 'react';
import {ActiveWorkspaceProvider} from '@common/workspace/active-workspace-id-context';
import {authGuard} from '@common/auth/guards/auth-route';

const lazyRoute = async (
  cmp: keyof typeof import('@app/dashboard/routes/dashboard-routes.lazy'),
) => {
  const exports = await import('@app/dashboard/routes/dashboard-routes.lazy');
  return {
    Component: exports[cmp],
  };
};

export const dashboardRoutes: RouteObject[] = [
  {
    path: 'dashboard',
    loader: () => authGuard({permission: 'projects.create'}),
    element: (
      <ActiveWorkspaceProvider>
        <Outlet />
      </ActiveWorkspaceProvider>
    ),
    children: [
      {
        index: true,
        lazy: () => lazyRoute('DashboardPage'),
      },
      {
        path: 'domains',
        lazy: () => lazyRoute('CustomDomainsPage'),
      },
      {
        path: 'templates',
        lazy: () => lazyRoute('NewProjectPage'),
      },
      {
        path: 'templates/:name',
        lazy: () => lazyRoute('TemplatePreviewPage'),
      },
    ],
  },
];
